import Bar from "../../../../components/horizontal_bar";
import HeaderOne from "../../../../components/headers/header_one";
import HeaderThree from "../../../../components/headers/header_three";
import Text from "../../../../components/paragraph";

import { Element, elements } from "./utils";
import color_palette from "../../../../assets/colors";

const VacantStagingWhySection = () => {
	const Number = ({ number, text, color }: Element) => {
		return (
			<div className="flex flex-row items-center w-full">
				<div
					className={`flex items-center justify-center text-white w-16 h-16 rounded-full`}
					style={{ border: `3px solid ${color}` }}
				>
					<span className="text-[47px] mb-2">{number}</span>
				</div>
				<div className="flex flex-col items-start w-full">
					<Bar color={color} />
					<Text color={color_palette.white} className={"text-regular font-normal pl-4"}>
						{text}
					</Text>
				</div>
			</div>
		);
	};

	return (
		<div className={"bg-medium-gray py-10 md:px-40 w-full my-10 px-4"}>
			<div className="flex flex-col items-center text-center">
				<div className="mb-10 flex flex-row items-center w-full">
					<div className="md:w-1/4 w-0">
						<Bar color={color_palette["dark-blue"]} />
					</div>
					<div className="md:w-1/2 w-full">
						<HeaderOne className={"text-lg-header font-medium text-center w-full"} color={color_palette.white}>
							Interested in a Redesign?
						</HeaderOne>
					</div>
					<div className="md:w-1/4 w-0">
						<Bar color={color_palette["dark-blue"]} />
					</div>
				</div>
				<HeaderThree color={color_palette.white}>Let’s connect about your project, budget and needs.</HeaderThree>
			</div>
			<div className="flex flex-col items-right mt-4">
				{elements.map(({ number, text, color }: Element, index: number) => (
					<div className="my-2" key={index}>
						<Number color={color} number={number} text={text} />
					</div>
				))}
			</div>
		</div>
	);
};

export default VacantStagingWhySection;
