import HeaderOne from "../../../../components/headers/header_one";
import Text from "../../../../components/paragraph";

import color_palette from "../../../../assets/colors";

import FallPorchPicture from "../../../../assets/images/home_images/fall_porch_picture.jpg";

const PorchSection = () => {
	return (
		<div className={"relative flex md:flex-row flex-col items-center justify-between md:px-40 my-10 px-4"}>
			<div className={"relative overflow-hidden md:w-[37%] w-full h-[300px] shadow-regular"}>
				<img className={"rounded-md"} src={FallPorchPicture} alt={"Porch with fall decorations"} />
			</div>
			<div className={"flex flex-col items-start md:ml-10 md:w-[63%] w-full mt-8"}>
				<div className={"bg-dark-blue w-full px-4 rounded-md"}>
					<HeaderOne color={color_palette.white}>Porch/Front Entry Design/Makeover</HeaderOne>
				</div>
				<Text className={"w-full px-4 flex flex-col items-start justify-evenly md:h-[160px] h-[270px]"}>
					Your front porch is part of your home’s curb appeal.  The front porch entrance gives the homeowner an opportunity
					to make a positive first impression welcoming guests and potential home buyers. Whether you are looking to
					enhance your home's curb appeal or create a welcoming entrance for both you and your guests, I can design and
					create exterior vignettes that will revive and refresh your outdoor space.
				</Text>
			</div>
		</div>
	);
};

export default PorchSection;
