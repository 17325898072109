import Bar from "../../../../components/horizontal_bar";
import HeaderOne from "../../../../components/headers/header_one";
import HeaderThree from "../../../../components/headers/header_three";
import Text from "../../../../components/paragraph";

import color_palette from "../../../../assets/colors";

import CoffeeMugBookTable from "../../../../assets/images/stock_images/coffee_mug_book_table.jpg";

const IntroSection = () => {
	return (
		<div className="bg-dark-gray w-full py-10 mb-10">
			<div className="flex flex-row items-center w-full mb-8 md:px-40 px-4">
				<div className={"md:w-[21%] w-0"}>
					<Bar color={color_palette["light-blue"]} />
				</div>
				<HeaderOne color={color_palette.white} className={"md:w-[58%] w-full mx-10 text-center text-lg-header font-medium"}>
					Home Decorating & Interior Design
				</HeaderOne>
				<div className={"md:w-[21%] w-0"}>
					<Bar color={color_palette["light-blue"]} />
				</div>
			</div>
			<Text className={"text-regular font-normal md:px-40 px-4 text-center"} color={color_palette["off-white"]}>
				See your space in a whole new light and designs to complement every room.
			</Text>
			<div className="flex flex-row items-center w-full my-8 md:px-40 px-4">
				<div className={"md:w-[23%] w-0"}>
					<Bar color={color_palette["medium-blue"]} />
				</div>
				<HeaderOne
					color={color_palette.white}
					className={"md:w-[54%] w-full md:mx-10 text-center text-lg-header font-medium"}
				>
					Design Services for Every Budget
				</HeaderOne>
				<div className={"md:w-[23%] w-0"}>
					<Bar color={color_palette["medium-blue"]} />
				</div>
			</div>
			<Text className={"text-regular font-normal md:px-40 px-4 text-center my-8"} color={color_palette["off-white"]}>
				My goal as your designer is to pinpoint what works for your family, lifestyle, personal style and passions so that
				your redesigned space is organized, comfortable, functional and stylish. 
			</Text>
			<div className={"relative flex flex-col items-start md:pl-40 px-4"}>
				<div className={"flex flex-col items-start md:w-3/4 max-[1250px]:w-5/12 my-4"}>
					<HeaderThree className={"text-sm-header font-normal font-bold mb-4"} color={color_palette.white}>
						Outdated
					</HeaderThree>
					<Bar className={"w-full h-[3px] border-0 mb-4"} color={color_palette["dark-blue"]} />
					<Text className={"text-regular font-normal md:w-[65%] w-full"} color={color_palette["off-white"]}>
						Over time, homes can begin to feel and look outdated. Homeowners can grow tired of seeing the same décor and
						vignettes throughout the house and redecorating trends change constantly so updating the look within the home
						provides a fresh, new and updated space.
					</Text>
				</div>
				<div className={"flex flex-col items-start md:w-3/4 max-[1250px]:w-full my-4"}>
					<HeaderThree className={"text-sm-header font-normal mb-6 font-bold"} color={color_palette.white}>
						Life Events
					</HeaderThree>
					<Bar className={"w-full h-[3px] border-0 mb-4"} color={color_palette["dark-blue"]} />
					<Text className={"text-regular font-normal md:w-[65%] w-full"} color={color_palette["off-white"]}>
						Life events are a big reason for a home redesign. One of the most common life events that leads to
						redecorating your home is having a family where a nursery is designed and decorated.
					</Text>
				</div>
				<div className={"flex flex-col items-start md:w-3/4 max-[1250px]:w-full my-4"}>
					<HeaderThree className={"text-sm-header font-normal mb-6 font-bold"} color={color_palette.white}>
						Increasing Value
					</HeaderThree>
					<Bar className={"w-full h-[3px] border-0 mb-4"} color={color_palette["medium-blue"]} />
					<Text className={"text-regular font-normal md:w-[65%] w-full"} color={color_palette["off-white"]}>
						Home ownership is the biggest investment you’ll ever make. Redecorating your home is a responsible and
						advisable way to maintain and nurture that investment and value to your property.
					</Text>
				</div>
				<div className={"flex flex-col items-start md:w-3/4 max-[1250px]:w-full my-4"}>
					<HeaderThree className={"text-sm-header font-normal mb-6 font-bold"} color={color_palette.white}>
						Organization
					</HeaderThree>
					<Bar className={"w-full h-[3px] border-0 mb-4"} color={color_palette["light-blue"]} />
					<Text className={"text-regular font-normal md:w-[65%] w-full"} color={color_palette["off-white"]}>
						Everyone grows and changes over time. As children grow older, their interests and routines change. All of
						these ongoing changes to your home may be necessary in order to make sure that everyone is getting the most
						out of their space in as many ways as possible. By engaging in a redecorating project, there’s an opportunity
						to eliminate clutter and reorganize things or belongings to reflect changes over time.
					</Text>
				</div>
				<img
					className={
						"absolute top-[4%] md:right-[12%] right-[3%] md:w-[31vw] w-[200px] md:min-w-[400px] overflow-hidden shadow-regular rounded-md"
					}
					src={CoffeeMugBookTable}
					alt={"Table with book and coffee mug"}
				/>
			</div>
		</div>
	);
};

export default IntroSection;
