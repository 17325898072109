import Bar from "../../../components/horizontal_bar";
import HeaderOne from "../../../components/headers/header_one";
import HeaderThree from "../../../components/headers/header_three";
import Text from "../../../components/paragraph";

import { AcronymElement, acronym_items } from "./util";

import color_palette from "../../../assets/colors";

const HomePhilosophySection = () => {
	const AcronymLetter = ({ letter, title, description, color }: AcronymElement) => {
		return (
			<div className="flex flex-row items-center w-full">
				<div
					className={"flex items-center justify-center text-white w-16 h-16 rounded-full"}
					style={{ border: `3px solid ${color}` }}
				>
					<span className="text-[47px] p-0 m-0 pb-1">{letter}</span>
				</div>
				<div className="flex flex-col items-start w-full">
					<HeaderThree className={"text-sm-header font-medium pl-4"} color={color_palette.white}>
						{title}
					</HeaderThree>
					<Bar color={color} />
					<Text color={color_palette.white} className={"text-regular font-normal pl-4"}>
						{description}
					</Text>
				</div>
			</div>
		);
	};

	return (
		<div className="flex flex-col items-center md:px-28 py-10 w-full bg-dark-gray my-10">
			<div className="flex flex-col items-center">
				<div className="mb-10 flex flex-row items-center w-full md:px-10 px-4">
					<div className="md:w-3/12">
						<Bar color={color_palette["dark-blue"]} />
					</div>
					<HeaderOne className={"text-lg-header font-medium text-center md:w-[55%] w-full"} color={color_palette.white}>
						Staging and Redesign Philosophy
					</HeaderOne>
					<div className="md:w-3/12">
						<Bar color={color_palette["dark-blue"]} />
					</div>
				</div>
				<Text className={"text-regular font-normal mb-10 text-center md:px-24 px-4"} color={color_palette.white}>
					My staging approach is using what you already have and love to transform your home through decorative groupings
					of art or accessories creating an emotional connection to the space. Online buyers can easily browse interior
					shots of professionally staged rooms and virtually connect with your space. I want the buyer to “Feel Home”.{" "}
				</Text>
				<Text className={"text-regular font-normal mb-10 text-center px-24 px-4"} color={color_palette.white}>
					My redesigning approach centers around understanding your family’s style, needs and comfort and making those a
					priority. Vignettes are created to establish a more inviting feel or refresh a space. I highlight your pieces or
					collections to create a space that is the perfect reflection of you.
				</Text>
			</div>
			<div className="flex flex-col items-right">
				<HeaderOne className={"text-lg-header font-medium mb-10 text-center"} color={color_palette.white}>
					FEEL HOME
				</HeaderOne>
				{acronym_items.map(({ letter, title, description, color }: AcronymElement, index: number) => (
					<div className="my-5" key={index}>
						<AcronymLetter color={color} letter={letter} title={title} description={description} />
					</div>
				))}
			</div>
		</div>
	);
};

export default HomePhilosophySection;
