import React from "react";

type Props = {
	className?: string;
	children: React.ReactNode;
	color?: string;
};

const HeaderThree = ({ className, children, color }: Props) => {
	return (
		<h3 className={className ?? "text-sm-header font-medium"} style={{ color: color ?? "black" }}>
			{children}
		</h3>
	);
};

export default HeaderThree;
