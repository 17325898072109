type Props = {
	className?: string;
	color?: string;
};

const Bar = ({ className, color }: Props) => {
	return <hr className={className ?? "w-full h-[3px] border-0"} style={{ backgroundColor: color ?? "#B4CBD9" }} />;
};

export default Bar;
