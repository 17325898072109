import HeaderOne from "../../../components/headers/header_one";

import color_palette from "../../../assets/colors";

import MomPicture from "../../../assets/images/mom_pictures/mom_picture_porch.jpg";

const HomeUpperSection = () => {
	return (
		<div className="flex flex-col items-center my-10 w-11/12 text-center md:px-28 px-4">
			<HeaderOne className="w-full text-center text-lg-header font-medium mb-10">Creating, Designing, Staging</HeaderOne>
			<p className="mb-6 text-regular">
				My passion is designing with purpose making simple, classic and small updates for a big impact creating timeless and
				beautiful designs to refresh every space indoors and out. My designs inspire simplicity and prioritizing function
				while creating a welcoming environment and spaces that reflect organization.
			</p>
			<p className="text-regular">
				My design services cover everything from vacation rentals, new builds, redesigns and e-designs to vacant and occupied
				staging.
			</p>
			<div className="relative md:w-full md:h-[140px] w-full max-[1415px]:h-[160px] max-[1225px]:h-[190px] max-[1125px]:h-[260px] max-[1000px]:h-[320px] border-[3px] border-dark-blue mt-36 rounded-md">
				<div className="relative absolute top-[-50px] md:w-full md:h-18 md:px-36">
					<HeaderOne
						className="h-full text-center text-lg-header font-medium bg-dark-blue py-2 rounded-md w-full"
						color={color_palette["white"]}
					>
						My Mission
					</HeaderOne>
					<div className="absolute md:top-[-10px] md:right-[90px] md:w-56 md:h-64 w-44 h-48 overflow-hidden shadow-regular rounded-md left-[-30px] top-[105px]">
						<img alt={"Joelle Hau"} style={{ objectPosition: "55% 27%" }} src={MomPicture} />
					</div>
					<p className="absolute w-[58%] md:w-full top-[75px] left-[160px] text-left pl-4 md:pl-40 md:pr-[350px] text-regular bg-white rounded-md md:border-none border-[3px] md:bg-transparent border-dark-blue md:p-0 p-2">
						My mission is to provide professional expertise to reflect the lifestyle of clients, while also creating
						clean and simple designs for a comfortable environment that offers style and function to the busiest room.
					</p>
				</div>
			</div>
		</div>
	);
};

export default HomeUpperSection;
