import Bar from "../../../../components/horizontal_bar";
import HeaderOne from "../../../../components/headers/header_one";
import HeaderThree from "../../../../components/headers/header_three";
import Text from "../../../../components/paragraph";

import color_palette from "../../../../assets/colors";

import CoffeeMugBookTable from "../../../../assets/images/stock_images/coffee_mug_book_table.jpg";

const SalesSection = () => {
	return (
		<div className="bg-dark-gray w-full my-10 py-10 md:px-28">
			<div className="flex flex-row items-center w-full mb-8 md:px-10 px-4">
				<div className={"md:w-[35%] w-0"}>
					<Bar color={color_palette["light-blue"]} />
				</div>
				<HeaderOne color={color_palette.white} className={"md:w-[30%] w-full text-center text-lg-header font-medium"}>
					What is Staging?
				</HeaderOne>
				<div className={"md:w-[35%] w-0"}>
					<Bar color={color_palette["light-blue"]} />
				</div>
			</div>
			<Text className={"text-regular font-normal px-10 text-center"} color={color_palette["off-white"]}>
				Home Staging is the preparation of a home to sell by a professional who specializes in their form of design and has
				been certified and trained on the art of home staging. This generally involves moving furniture around and optimizing
				an already existing space. A professional home stager has the resources, the knowledge and can objectively view the
				home as a product and highlight irs assets while downplayingits flaws. Homes must be prepped and cleaned prior to
				stager arriving.
			</Text>
			<div className="flex flex-row items-center w-full my-8 px-10">
				<div className={"md:w-[26%] w-0"}>
					<Bar color={color_palette["medium-blue"]} />
				</div>
				<HeaderOne color={color_palette.white} className={"md:w-[48%] w-full text-center text-lg-header font-medium"}>
					Why Stage Your Home?
				</HeaderOne>
				<div className={"md:w-[26%] w-0"}>
					<Bar color={color_palette["medium-blue"]} />
				</div>
			</div>
			<div className={"relative flex flex-col items-start md:pl-10 md:px-0 px-4"}>
				<div className={"flex flex-col items-start md:w-3/4 max-[1250px]:w-5/12 my-4"}>
					<HeaderThree className={"text-sm-header font-normal font-bold mb-4"} color={color_palette.white}>
						Tax Deductions
					</HeaderThree>
					<Bar className={"w-full h-[3px] border-0 mb-4"} color={color_palette["dark-blue"]} />
					<Text className={"text-regular font-normal md:w-[65%] w-full"} color={color_palette["off-white"]}>
						If you stage your home, the IRS views the staging as a legitimate selling expense for primary and secondary
						homes. This means that your staging costs will be subtracted from home sale proceeds on taxes.
					</Text>
				</div>
				<div className={"flex flex-col items-start md:w-3/4 max-[1250px]:w-full my-4"}>
					<HeaderThree className={"text-sm-header font-normal mb-6 font-bold"} color={color_palette.white}>
						Staged Homes Sell Faster
					</HeaderThree>
					<Bar className={"w-full h-[3px] border-0 mb-4"} color={color_palette["dark-blue"]} />
					<Text className={"text-regular font-normal md:w-[65%] w-full"} color={color_palette["off-white"]}>
						According to a study of over 13,000 homes by the Real Estate Staging Association (RESA), professionally
						staged homes sell much faster averaging only 23 days on the market.
					</Text>
				</div>
				<div className={"flex flex-col items-start md:w-3/4 max-[1250px]:w-full my-4"}>
					<HeaderThree className={"text-sm-header font-normal mb-6 font-bold"} color={color_palette.white}>
						Staged Home Sell For More Money
					</HeaderThree>
					<Bar className={"w-full h-[3px] border-0 mb-4"} color={color_palette["medium-blue"]} />
					<Text className={"text-regular font-normal md:w-[65%] w-full"} color={color_palette["off-white"]}>
						In that same RESA study of 13,000 homes, 85% sold for 5 - 23% over the list price
					</Text>
				</div>
				<div className={"flex flex-col items-start md:w-3/4 max-[1250px]:w-full my-4"}>
					<HeaderThree className={"text-sm-header font-normal mb-6 font-bold"} color={color_palette.white}>
						Staged Homes Provide Return On Investment
					</HeaderThree>
					<Bar className={"w-full h-[3px] border-0 mb-4"} color={color_palette["light-blue"]} />
					<Text className={"text-regular font-normal md:w-[65%] w-full"} color={color_palette["off-white"]}>
						With an average investment of 1%, approximately 75% of home sellers saw a 5-15% over asking price return on
						investment.
					</Text>
				</div>
				<img
					className={
						"absolute top-[0%] right-[3%] md:w-[31vw] w-[200px] md:min-w-[400px] overflow-hidden shadow-regular rounded-md"
					}
					src={CoffeeMugBookTable}
					alt={"Table with book and coffee mug"}
				/>
			</div>
		</div>
	);
};

export default SalesSection;
