import Bar from "../../../../components/horizontal_bar";
import HeaderOne from "../../../../components/headers/header_one";
import Text from "../../../../components/paragraph";

import { Element, benefit_list } from "./utils";

import color_palette from "../../../../assets/colors";

const BenefitsSection = () => {
	const TextAndLineSection = ({ text, color }: Element) => {
		return (
			<div className={"w-full flex flex-col items-start"}>
				<Bar color={color} />
				<Text color={color_palette.white}>{text}</Text>
			</div>
		);
	};

	return (
		<div className={"bg-dark-gray w-full py-10 flex flex-col items-start md:px-40 my-10 px-4"}>
			<div className={"mb-4"}>
				<HeaderOne color={color_palette.white}>
					Benefits of Redesign to Clients - My Goal as Designer and Stylist of the Space
				</HeaderOne>
			</div>
			{benefit_list.map(({ text, color }: Element, index: number) => {
				return (
					<div key={index} className={"my-4 w-full"}>
						<TextAndLineSection text={text} color={color} />
					</div>
				);
			})}
		</div>
	);
};

export default BenefitsSection;
