import React from "react";
import { Route, Routes } from "react-router-dom";

import About from "../pages/about";
import Contact from "../pages/contact";
import EDesignServices from "../pages/services/e_design_services";
import Home from "../pages/home";
import NotFoundPage from "../partials/404";
import Portfolio from "../pages/portfolio";
import RedesignServices from "../pages/services/redesign_services";
import StagingServices from "../pages/services/staging_services";
import VacationServices from "../pages/services/vacation_services";

import { LoadingProps } from "../common/types";

const Router: React.FC<LoadingProps> = ({ set_loading }) => {
	return (
		<Routes>
			<Route path="/" element={<Home set_loading={set_loading} />} />
			<Route path="/services/staging-services" element={<StagingServices set_loading={set_loading} />} />
			<Route path="/services/redesign-services" element={<RedesignServices set_loading={set_loading} />} />
			<Route path="/services/e-design-services" element={<EDesignServices set_loading={set_loading} />} />
			<Route path="/services/vacation-services" element={<VacationServices set_loading={set_loading} />} />
			<Route path="/portfolio" element={<Portfolio set_loading={set_loading} />} />
			<Route path="/about" element={<About set_loading={set_loading} />} />
			<Route path="/contact" element={<Contact set_loading={set_loading} />} />
			<Route path="/*" element={<NotFoundPage />} />
		</Routes>
	);
};

export default Router;
