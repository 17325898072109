import Bar from "../../../../components/horizontal_bar";
import HeaderOne from "../../../../components/headers/header_one";
import Text from "../../../../components/paragraph";

import { phase_box_style } from "./utils";

import color_palette from "../../../../assets/colors";

import DiningRoomAndKitchen from "../../../../assets/images/home_images/dining_room_and_kitchen.jpg";
import HeaderThree from "../../../../components/headers/header_three";

const ServicePhasesSection = () => {
	const PhaseBox = ({ header, body, color }: { header: string; body: string; color: string }) => {
		return (
			<div
				className={"bg-black md:h-[180px] md:h-[220px] min-h-[360px] w-full md:py-4 py-2 md:px-20 px-2"}
				style={{ backgroundColor: color }}
			>
				<HeaderThree color={color_palette["medium-blue"]}>{header}</HeaderThree>
				<Text color={color_palette["white"]}>{body}</Text>
			</div>
		);
	};

	return (
		<div className={"relative flex flex-col w-full my-10"}>
			<div className={"flex flex-col items-start self-end w-full md:px-40 px-4"}>
				<HeaderOne>Real Estate Staging & Consulting Services</HeaderOne>
				<Bar className={"w-full h-[3px] border-0 mb-6"} color={color_palette["medium-blue"]} />
				<div className="relative w-full h-[380px] overflow-hidden rounded-md">
					<img
						className={"absolute z-0 md:left-[-100px] md:top-[-170px] md:min-w-[86vw] min-w-[92vw] h-[52vw]"}
						src={DiningRoomAndKitchen}
						alt={"Kitchen on the left and dining room in the center."}
					/>
				</div>
			</div>
			<div className={"absolute top-[38%] flex flex-row items-center md:shadow-regular w-full"}>
				{phase_box_style.map(({ header, body, color }: { header: string; body: string; color: string }, index: number) => {
					return (
						<div className={"w-[33.333%]"} key={index}>
							<PhaseBox header={header} body={body} color={color} />
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default ServicePhasesSection;
