import React from "react";

const Loader: React.FC<{}> = () => {
	return (
		<div className={"relative rounded-xl mt-2 flex flex-row items-center rounded-md bg-dark-gray p-2"}>
			<div className="relative h-[32px] w-[32px] border-[10px] border-white rounded-full mr-3 animate-spin">
				<div
					className="absolute top-[-10px] right-[-10px] h-[32px] w-[32px] border-[10px] border-medium-gray rounded-full"
					style={{ clipPath: "polygon(0% 0%, 50% 50%, 0% 100%)" }}
				/>
			</div>
			<h3 className={"text-sm-header text-white"}>Loading assets...</h3>
		</div>
	);
};

export default Loader;
