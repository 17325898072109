import React, { useEffect } from "react";

import IntroSection from "./intro_section";
import PDFBed from "../../../components/pdf_bed";
import SalesSection from "./sales_section";

import { LoadingProps } from "../../../common/types";

const VacationServices: React.FC<LoadingProps> = ({ set_loading }) => {
	const vacation_rental_guide_url: string = "/pdfs/vacation_rental_guide.pdf";

	useEffect(() => {
		window.scrollTo({
			top: 0,
		});

		set_loading(true);
	}, []);

	return (
		<div className={"flex flex-col items-center pt-20 w-full mb-20 pb-20"}>
			<IntroSection />
			<SalesSection />
			<PDFBed url={vacation_rental_guide_url} id={"vacation_rental_guide_canvas"} />
		</div>
	);
};

export default VacationServices;
