import color_palette from "../../../assets/colors";

export type AcronymElement = {
	color: string;
	letter: string;
	title: string;
	description: string;
};

export const acronym_items: Array<AcronymElement> = [
	{
		color: color_palette["dark-blue"],
		letter: "F",
		title: "First Impressions",
		description:
			"I will walk through your home room by room taking note of my first impressions noting what grabs me, what glares at me and what gives me a feeling of home.",
	},
	{
		color: color_palette["medium-blue"],
		letter: "E",
		title: "Eliminating Clutter",
		description:
			"The packing starts now. I pack away a good percentage of everything in the room in order to create a more clean, open and spacious feeling.",
	},
	{
		color: color_palette["light-blue"],
		letter: "E",
		title: "Emphasizing Positive Elements",
		description:
			"Finding your focal points, I will emphasize what we love about the room and hide or distract the negative aspects of the rooms.",
	},
	{
		color: color_palette.white,
		letter: "L",
		title: "Lighten Up",
		description:
			"Focusing on lighting and color, I will make each room into a bright, warm and inviting space buyers can respond to.",
	},
	{
		color: color_palette.white,
		letter: "H",
		title: "Home Buyer's Appeal",
		description:
			"In order for a buyer to “feel home”, they need to not be reminded of this being home. I will depersonalize the space while retaining the warmth.",
	},
	{
		color: color_palette["light-blue"],
		letter: "O",
		title: "Obligations",
		description:
			"The dirty work speaks for itself, but is often overlooked because you have lived here for so long. Let’s get rid of the buyer’s mental repair list by doing it ourselves.",
	},
	{
		color: color_palette["medium-blue"],
		letter: "M",
		title: "Modernizing",
		description:
			"This involves updating your style and building simple accessories out of what you already have to create clean lines and forms. In some cases, I will recommend purchases as valuable investments in the staging process.",
	},
	{
		color: color_palette["dark-blue"],
		letter: "E",
		title: "Emotional Connection Points",
		description:
			"This concept focuses on my positioning strategy. I will show you how to build in these subtleties in order to make buyers subconsciously desire to live here and “feel home”. The “emotional connection points” are where marketing strategy really comes into play.",
	},
];
