import Bar from "../../../../components/horizontal_bar";
import HeaderOne from "../../../../components/headers/header_one";
import Text from "../../../../components/paragraph";

import color_palette from "../../../../assets/colors";

import WineVignette from "../../../../assets/images/home_images/wine_vignette.jpg";

const VignetteSection = () => {
	return (
		<div
			className={
				"relative flex md:flex-row flex-col items-center justify-between py-4 bg-dark-gray md:h-[240px] h-[630px] my-10 md:px-40 px-4"
			}
		>
			<div className={"flex flex-col items-start my-4"}>
				<HeaderOne color={color_palette.white}>Vignette</HeaderOne>
				<Bar className="w-full h-[3px] border-0 my-[15px]" color={color_palette.white} />
				<Text color={color_palette.white}>
					Vignettes are decorative designs to enhance a space and make it more inviting. It can be as simple as a few
					decorative items arranged on a table or it can involve an entire room created to establish a more inviting feel
					or refresh a space.  Through simple vignettes, I can highlight and display your pieces, collections or decor to
					create a space that is the perfect reflection of you.
				</Text>
			</div>
			<img
				className={"md:w-[37%] w-full shadow-regular rounded-md"}
				src={WineVignette}
				alt={"Vignette with wine bottle, wine glasses, cookbook, and assorted nuts"}
			/>
		</div>
	);
};

export default VignetteSection;
