import React, { useEffect } from "react";

import IntroSection from "./intro_section";

import { LoadingProps } from "../../common/types";

const Contact: React.FC<LoadingProps> = ({ set_loading }) => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
		});

		set_loading(true);
	}, []);

	return (
		<div className={"flex flex-col items-center pt-20 w-full mb-20"}>
			<IntroSection />
		</div>
	);
};

export default Contact;
