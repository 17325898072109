// Dining Room
import NewDiningRoom from "../../../assets/images/home_images/dining_room_front.jpg";
import OldDiningRoom from "../../../assets/images/home_images/old_dining_room.webp";

// Basement
import NewBasement from "../../../assets/images/home_images/new_basement.jpg";
import OldBasement from "../../../assets/images/home_images/old_basement.webp";

// Other Dining Room
import NewOtherDiningRoom from "../../../assets/images/home_images/full_dining_room.jpg";
import OldOtherDiningRoom from "../../../assets/images/home_images/old_other_dining_room.webp";

// Kitchens
import NewKitchen from "../../../assets/images/home_images/main_kitchen_photo.jpg";
import OldKitchen from "../../../assets/images/home_images/old_main_kitchen.webp";

// Living Rooms
import NewLivingRoom from "../../../assets/images/home_images/main_living_room_image.jpg";
import OldLivingRoom from "../../../assets/images/home_images/old_main_living_room.webp";

type Transformation = {
	before: string;
	after: string;
};

export const transformations: Array<Transformation> = [
	{
		before: OldDiningRoom,
		after: NewDiningRoom,
	},
	{
		before: OldBasement,
		after: NewBasement,
	},
	{
		before: OldOtherDiningRoom,
		after: NewOtherDiningRoom,
	},
	{ before: OldKitchen, after: NewKitchen },
	{ before: OldLivingRoom, after: NewLivingRoom },
];
