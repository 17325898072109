import { useNavigate } from "react-router-dom";

import Button from "../../../components/button";
import HeaderOne from "../../../components/headers/header_one";

import color_palette from "../../../assets/colors";

import LivingRoomAndDiningRoom from "../../../assets/images/home_images/living_room_and_dining_room.jpg";

const ClosingSection = () => {
	const navigate = useNavigate();

	return (
		<div className="relative w-full h-[300px] overflow-hidden md:mb-10">
			<img
				className="absolute z-0 md:left-[-100px] md:top-[-300px] md:min-w-[120vw] md:h-[75vw]"
				src={LivingRoomAndDiningRoom}
				alt="Dining Room"
			/>
			<div className="absolute md:top-[25%] top-0 z-20 w-full md:h-[155px] h-[250px] flex flex-col items-center bg-white opacity-[80%] py-1 md:py-6 md:px-18 px-2 text-center">
				<HeaderOne>See your space in a whole new light and designs to complement every room.</HeaderOne>
				<div className="flex flex-row items-center justify-evenly w-full mt-[10px]">
					<Button
						default_color={color_palette.black}
						hover_color={color_palette.black}
						active_color={color_palette.black}
						onClick={() => navigate("/services/staging-services")}
					>
						Services
					</Button>
				</div>
			</div>
		</div>
	);
};

export default ClosingSection;
