import color_palette from "../../../../assets/colors";

export type Element = {
	text: string;
	color: string;
};

export const benefit_list: Array<Element> = [
	{
		text: "Bring convenience to the function of the space to create a more spacious feel by rearranging furniture and decor.",
		color: color_palette["dark-blue"],
	},
	{
		text: "Bring comfort to the function of the space by creating rooms that are warm and welcoming by adding a simple kitchen vignette of favorite items to create a coffee bar or changing out seasonal pillows, throws  and adding additional decor.",
		color: color_palette["medium-blue"],
	},
	{
		text: "Bring meaning into the form of the space by highlighting favorite possessions or creating focal points displayed on a mantel, coffee table or bookshelf.",
		color: color_palette["light-blue"],
	},
	{
		text: "Bring emotion into the form of space by incorporating your personal possessions such as a tray for your favorite mugs on the kitchen counter, a cozy spot for reading or a backyard for relaxing and entertaining.",
		color: color_palette["medium-blue"],
	},
	{
		text: "Bring vision into the space by fulfilling your wish list for function of the space by updating your style and modernizing your space.",
		color: color_palette["dark-blue"],
	},
];
