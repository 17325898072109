import Kitchen from "../../../../assets/images/home_images/kitchen.jpg";
import Text from "../../../../components/paragraph";

type CircleFill = {
	number: number;
	description: string;
	poly_fill: string;
};

const circle_fills: Array<CircleFill> = [
	{
		number: 68,
		description: "of staged homes sold for 9-25% MORE.",
		poly_fill: "polygon(0% 0%, 50% 0%, 50% 50%, 100% 68%, 100% 100%, 0% 100%)",
	},
	{
		number: 85,
		description: "of staged homes sold for 6-25% MORE.",
		poly_fill: "polygon(0% 0%, 50% 0%, 50% 50%, 100% 20%, 100% 100%, 0% 100%)",
	},
	{
		number: 100,
		description: "of agents say staging has a positive impact.",
		poly_fill: "polygon(0% 0%, 100% 0%, 100% 100%, 0% 100%)",
	},
];

const IntroSection = () => {
	const StatCircle = ({ number, description, poly_fill }: CircleFill) => {
		return (
			<div className="relative md:h-[195px] md:w-[195px] h-[60px] w-[60px] border-[15px] border-light-blue rounded-full">
				<div
					className="absolute top-[-15px] right-[-15px] md:h-[195px] md:w-[195px] h-[60px] w-[60px] border-[15px] border-dark-blue rounded-full"
					style={{ clipPath: `${poly_fill}` }}
				/>
				<div className="absolute h-[165px] w-[165px] flex flex-col items-center justify-center md:top-[0px] md:left-[0px] top-[30px] left-[-70px]">
					<span className="text-[36px] text-white h-[20%] my-4 items-center m-0 p-0">{number}%</span>
					<Text className={"h-[80%] m-0 p-0 text-center text-regular font-normal md:w-[150px] w-[100px]"} color="white">
						{description}
					</Text>
				</div>
			</div>
		);
	};

	return (
		<div className="relative w-full h-[300px] overflow-hidden">
			<div className="absolute z-10 top-0 left-0 min-w-[120vw] h-[80vw] bg-black opacity-[70%]"></div>
			<img className="absolute z-0 md:left-[-100px] md:top-[-300px] min-w-[120vw] h-[80vw]" src={Kitchen} alt="Dining Room" />
			<div className="absolute top-[13%] z-[11] flex flex-row items-center justify-between w-full h-[120px] md:h-[210px] justify-center md:px-[20vw] px-[40px]">
				{circle_fills.map(({ number, description, poly_fill }: CircleFill) => {
					return <StatCircle number={number} description={description} poly_fill={poly_fill} />;
				})}
			</div>
		</div>
	);
};

export default IntroSection;
