import React from "react";

type Props = {
	className?: string;
	children: React.ReactNode;
	color?: string;
};

const HeaderOne = ({ className, children, color }: Props) => {
	return (
		<h1 className={className ?? `text-lg-header font-medium `} style={{ color: color ?? "black" }}>
			{children}
		</h1>
	);
};

export default HeaderOne;
