import HeaderOne from "../../../components/headers/header_one";
import Button from "../../../components/button";

import color_palette from "../../../assets/colors";

import DiningRoomAndKitchen from "../../../assets/images/home_images/dining_room_and_kitchen.jpg";

const IntroSection = () => {
	return (
		<div className={"w-full relative h-[350px] mb-10"}>
			<div className={"relative absolute z-0 top-0 left-0 overflow-hidden w-full h-full"}>
				<img
					className={"absolute md:top-[-315px]"}
					src={DiningRoomAndKitchen}
					alt="Design template with matching furniture"
				/>
			</div>
			<div className={"absolute z-10 top-0 left-0 bg-black opacity-[65%] w-full h-full"}></div>
			<div
				className={
					"absolute z-20 top-0 left-0 w-full h-full md:px-54 md:py-20 flex flex-col items-center px-4 py-3 text-center"
				}
			>
				<div className={"mb-8 w-full"}>
					<HeaderOne color={color_palette.white}>Have A Question About Your Staging and Design Project?</HeaderOne>
				</div>
				<p className={"text-white mb-8"}>
					If you are interested in scheduling a service and would like additional information, click the "Contact Form"
					button below to reach out!
				</p>
				<Button
					onClick={() =>
						window.open(
							"https://www.honeybook.com/widget/simply_stated_staging_designs_251564/cf_id/6452d7327d14a1032ef0a989"
						)
					}
					default_color={color_palette.white}
				>
					Contact Form
				</Button>
			</div>
		</div>
	);
};

export default IntroSection;
