import { useLocation } from "react-router";

import HeaderOne from "../../components/headers/header_one";
import HeaderThree from "../../components/headers/header_three";

import color_palette from "../../assets/colors";

const NotFoundPage = () => {
	const location = useLocation();

	return (
		<div className={"h-full min-h-[500px] w-full flex flex-col items-center justify-center font-bold"}>
			<HeaderOne color={color_palette["dark-blue"]}>404 Error</HeaderOne>
			<HeaderThree color={color_palette.black}>
				Page not found! You attempted to navigate to "{location.pathname}"; a page that does not exist.
			</HeaderThree>
		</div>
	);
};

export default NotFoundPage;
