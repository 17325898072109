import Bar from "../../../../components/horizontal_bar";
import HeaderOne from "../../../../components/headers/header_one";
import HeaderThree from "../../../../components/headers/header_three";
import Text from "../../../../components/paragraph";

import { NumberElement, number_items } from "./utils";
import color_palette from "../../../../assets/colors";

const VacantStagingWhySection = () => {
	const Number = ({ number, description, color }: NumberElement) => {
		return (
			<div className="flex flex-row items-center w-full">
				<div
					className={"flex items-center justify-center text-white w-16 h-16 rounded-full"}
					style={{ border: `3px solid ${color}` }}
				>
					<span className="text-[47px] p-0 m-0 pb-1">{number}</span>
				</div>
				<div className="flex flex-col items-start w-full">
					<Bar color={color} />
					<Text color={color_palette.white} className={"text-regular font-normal pl-4"}>
						{description}
					</Text>
				</div>
			</div>
		);
	};

	return (
		<div className={"bg-medium-gray py-10 md:px-40 px-4 w-full my-10"}>
			<div className="flex flex-col items-center mb-4">
				<div className="mb-10 flex flex-row items-center w-full">
					<div className="md:w-[18%] w-0">
						<Bar color={color_palette["dark-blue"]} />
					</div>
					<div className="border-dark-blue md:w-[60%] w-full">
						<HeaderOne className={"text-lg-header font-medium text-center w-full"} color={color_palette.white}>
							Why Staging a Vacant Home is Critical
						</HeaderOne>
					</div>
					<div className="md:w-[18%] w-0">
						<Bar color={color_palette["dark-blue"]} />
					</div>
				</div>
				<HeaderThree color={color_palette.white}>Four Reasons To Do So</HeaderThree>
			</div>
			<div className="flex flex-col items-right">
				{number_items.map(({ number, description, color }: NumberElement, index: number) => (
					<div className="my-2" key={index}>
						<Number color={color} number={number} description={description} />
					</div>
				))}
			</div>
		</div>
	);
};

export default VacantStagingWhySection;
