import React, { useEffect } from "react";

import HomeCertSection from "./certifications_section";
import HomeIntroSection from "./intro_section";
import HomeLinkSection from "./link_section";
import HomePhilosophySection from "./philosophy_section";
import HomeUpperSection from "./upper_section";
import HomeWhySection from "./why_section";

import { LoadingProps } from "../../common/types";

const Home: React.FC<LoadingProps> = ({ set_loading }) => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
		});

		set_loading(true);
	}, []);

	return (
		<div className={"flex flex-col items-center pt-20 w-full mb-20"}>
			<HomeIntroSection />
			<HomeUpperSection />
			<HomeWhySection />
			<HomeCertSection />
			<HomePhilosophySection />
			<HomeLinkSection />
		</div>
	);
};

export default Home;
