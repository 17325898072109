import { useNavigate } from "react-router-dom";

import DiningRoom from "../../../assets/images/home_images/dining_room.jpg";
import Button from "../../../components/button";
import HeaderOne from "../../../components/headers/header_one";
import Text from "../../../components/paragraph";

import color_palette from "../../../assets/colors";

const HomeLinkSection = () => {
	const navigate = useNavigate();

	return (
		<div className="relative w-full h-[320px] overflow-hidden my-10">
			<div className="absolute z-10 top-0 left-0 min-w-[120vw] h-[80vw] bg-black opacity-70"></div>
			<img
				className="absolute z-0 md:left-[-100px] md:top-[-300px] min-w-[120vw] h-[80vw]"
				src={DiningRoom}
				alt="Dining Room"
			/>
			<div className="absolute md:top-[25%] z-20 w-full md:h-[165px] flex flex-col items-center justify-between pt-4 md:pt-0 px-4">
				<HeaderOne color={color_palette.white}>Ready to Get Started?</HeaderOne>
				<Text color={color_palette.white}>
					If you are interested in learning more about my staging and design services, let’s connect!
				</Text>
				<div className="flex md:flex-row flex-col items-center justify-evenly md:w-[1050px] w-full md:mt-0 mt-4 h-[160px]">
					<Button
						default_color={color_palette.white}
						onClick={() => {
							navigate("/services/staging-services");
						}}
					>
						Services Page
					</Button>
					<Button
						default_color={color_palette.white}
						onClick={() => {
							navigate("/contact");
						}}
					>
						Contact Page
					</Button>
					<Button
						default_color={color_palette.white}
						onClick={() => {
							navigate("/about");
						}}
					>
						About Page
					</Button>
					<Button
						default_color={color_palette.white}
						onClick={() => {
							navigate("/portfolio");
						}}
					>
						Portfolio Page
					</Button>
				</div>
			</div>
		</div>
	);
};

export default HomeLinkSection;
