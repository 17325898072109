import color_palette from "../../../../assets/colors";

export type Element = {
	title: string;
	description: string;
	color: string;
	number: number;
};

export const elements: Array<Element> = [
	{
		title: "Customized Design Solutions",
		description:
			"I  understand that every Airbnb property is unique, and I tailor my design solutions to meet your specific needs. Whether you need help with interior design, furniture selection, decor, or all of the above,  I can create a customized design plan that fits your style, budget, and target audience.",
		color: color_palette["dark-blue"],
		number: 1,
	},
	{
		title: "Increased Bookings and Revenue",
		description:
			"My design expertise is not just about making your Airbnb property look beautiful; it’s also about maximizing its earning potential. I know how to create spaces that are not only visually appealing but also functional and optimized for guest comfort. A well-designed Airbnb property can attract more bookings, command higher rates, and generate more positive reviews, ultimately leading to increased revenue.",
		color: color_palette["medium-blue"],
		number: 2,
	},
	{
		title: "Hassle-Free Process",
		description:
			"I  understand that as an Airbnb host, your time is valuable. That’s why I strive to make our design service process as seamless and hassle-free as possible. From initial consultation to final installation, I take care of all the details, so you can focus on running your Airbnb business.",
		color: color_palette["light-blue"],
		number: 3,
	},
];
