import { useNavigate } from "react-router-dom";

import Button from "../../../components/button";

import main_living_room_image from "../../../assets/images/home_images/main_living_room_image.jpg";
import primary_logo from "../../../assets/icons/sssd_logo.png";

const HomeIntroSection = () => {
	const navigate = useNavigate();

	return (
		<div className="md:w-full md:h-[400px]">
			<div className="w-full h-full overflow-hidden relative">
				<div className="absolute z-0 w-full h-full top-0 bg-gradient-to-r from-black opacity-80"></div>
				<img src={main_living_room_image} alt="living room" />
			</div>
			<div className="absolute z-0 flex flex-col justify-evenly items-start md:h-72 h-[150px] md:w-1/2 w-auto md:ml-24 ml-4 absolute top-36">
				<img className="h-5/12 w-5/12 min-w-[200px] min-h-[70px]" src={primary_logo} alt="primary logo" />
				<h3 className="md:text-sm-header text-white text-regular md:my-0 my-4">
					<b>"Simplicity is the ultimate sophistication."</b> - Leonardo Da Vinci
				</h3>
				<Button default_color={"white"} onClick={() => navigate("/portfolio")}>
					See Simplicity
				</Button>
			</div>
		</div>
	);
};

export default HomeIntroSection;
