const color_palette = {
	"dark-blue": "#5C9DF2",
	"medium-blue": "#94BDF2",
	"light-blue": "#B4CBD9",
	black: "#0D0D0D",
	"dark-gray": "#262626",
	"medium-gray": "#525252",
	"light-gray": "#949494",
	"off-white": "#CACACA",
	white: "#EBEBEB",
};

export default color_palette;
