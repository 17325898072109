import HeaderThree from "../../../../components/headers/header_three";

const CloserSection = () => {
	return (
		<div className={"mb-20 md:px-40 text-center w-full px-4"}>
			<HeaderThree>
				As an Expert E-Design Services provider, I’ve been certified in creating professionally designed and concise space
				plans and mood boards  with beautiful trade only sourced products.  My online technology allows me to offer
				personalized expert design advice all over!
			</HeaderThree>
		</div>
	);
};

export default CloserSection;
