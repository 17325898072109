import Bar from "../../../../components/horizontal_bar";
import HeaderOne from "../../../../components/headers/header_one";
import HeaderThree from "../../../../components/headers/header_three";
import Text from "../../../../components/paragraph";

import { Element, elements } from "./utils";

const PhasesSection = () => {
	const AcronymLetter = ({ number, title, description, color }: Element) => {
		return (
			<div className="flex flex-row items-center w-full">
				<div className={"flex items-center justify-center w-16 h-16 rounded-full"} style={{ border: `3px solid ${color}` }}>
					<span className="text-[47px] p-0 m-0 pb-1 text-black">{number}</span>
				</div>
				<div className="flex flex-col items-start w-full">
					<HeaderThree className={"text-sm-header font-medium pl-4"}>{title}</HeaderThree>
					<Bar color={color} />
					<Text className={"text-regular font-normal pl-4"}>{description}</Text>
				</div>
			</div>
		);
	};

	return (
		<div className={"my-10 flex flex-col items-start md:px-40 px-4"}>
			<HeaderOne>Looking To Refresh Your Space?</HeaderOne>
			<div className={"my-[10px] flex flex-col items-start"}>
				<HeaderThree>My E-Design Is Simple</HeaderThree>
				<Text>
					With communication through email, a design form questionnaire and an online design sharing platform to gather the
					necessary information needed to create your customized design plan, your new and refreshed design awaits!
				</Text>
			</div>
			<div>
				{elements.map((element: Element, index: number) => {
					return (
						<div key={index} className={"my-[10px]"}>
							<AcronymLetter {...element} />
						</div>
					);
				})}
			</div>
		</div>
	);
};

export default PhasesSection;
