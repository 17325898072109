import HeaderOne from "../../../../components/headers/header_one";

import { rooms } from "./utils";

import FullDiningRoom from "../../../../assets/images/home_images/full_dining_room.jpg";
import OldHouseFoyer from "../../../../assets/images/home_images/old_house_foyer.webp";
import OldHouseLivingRoom from "../../../../assets/images/home_images/old_house_living_room.webp";
import HeaderThree from "../../../../components/headers/header_three";

const SpecificRoomSection = () => {
	return (
		<div className={"my-10 flex flex-col items-center w-full md:px-[300px] px-4"}>
			<HeaderOne>Styling Specific Rooms</HeaderOne>
			<div className={"flex flex-row items-center mt-10 w-full"}>
				<div className={"border-medium-blue border-[3px] w-full md:h-[1000px] h-[550px] rounded-md"}>
					<div className={"relative h-full h-full w-full"}>
						<img
							className={"absolute md:w-[450px] md:h-[300px] top-[1%] left-[-6%] rounded-md"}
							src={OldHouseLivingRoom}
							alt={"Living Room"}
						/>
						<img
							className={"absolute md:w-[450px] md:h-[300px] top-[35%] left-[-6%] rounded-md"}
							src={OldHouseFoyer}
							alt={"Foyer"}
						/>
						<img
							className={"absolute md:w-[450px] md:h-[300px] top-[69%] left-[-6%] rounded-md"}
							src={FullDiningRoom}
							alt={"Dining Room"}
						/>
					</div>
				</div>
				<div className={"w-full h-full flex flex-col items-start"}>
					{rooms.map((room, index) => {
						return (
							<div key={index}>
								<HeaderThree
									className={
										"text-sm-header font-medium py-1 md:px-4 px-2 border-2 border-medium-blue md:my-4 my-1 ml-[10px] rounded-md"
									}
								>
									{room}
								</HeaderThree>
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default SpecificRoomSection;
