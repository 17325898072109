import { useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

type ServiceObj = {
	label: string;
	url: string;
};

const service_list: Array<ServiceObj> = [
	{ label: "Staging Services", url: "/services/staging-services" },
	{ label: "Redesign Services", url: "/services/redesign-services" },
	{ label: "E-Design Services", url: "/services/e-design-services" },
	{ label: "Vacation Services", url: "/services/vacation-services" },
];

const NavigationBar = () => {
	const [show_services_menu, set_show_services_menu] = useState<boolean>(false);

	const navigate = useNavigate();
	const location = useLocation();

	const link_wrapper_style: string = "relative w-[20%]";
	const default_style: string = "hover:cursor-pointer hover:text-light-blue text-black w-full text-center";
	const default_services_style: string = "hover:text-light-blue text-black w-full text-center";
	const selected_style: string = "text-dark-blue w-full text-center";

	const selected = (url: string) => {
		if (location.pathname === url || (url === "/services" && location.pathname.includes(url))) {
			return selected_style;
		}

		return url === "/services" ? default_services_style : default_style;
	};

	const ServicesMenu = () => {
		return (
			<ul
				className="top-[50px] w-[170px] h-[150px] z-[10000] flex flex-col items-center justify-evenly bg-white rounded p-[5px] fixed"
				onMouseLeave={() => set_show_services_menu(false)}
			>
				{service_list.map(({ label, url }: ServiceObj, index: number) => {
					return (
						<li
							className="hover:cursor-pointer hover:text-light-blue text-left"
							key={index}
							onClick={() => navigate(url)}
						>
							{label}
						</li>
					);
				})}
			</ul>
		);
	};

	return (
		<div
			className="flex flex-col md:flex-row md:justify-around h-[90px] md:h-20 py-3 w-screen bg-white fixed top-0 left-0 z-50 text-regular justify-center items-center px-2"
			onClick={() => set_show_services_menu(false)}
		>
			<div className="flex flex-row md:flex-col md:items-end md:w-2/5 w-full justify-evenly">
				<h3 className="truncate text-sm-header md:w-7/12" style={{ fontFamily: "Vladimir Script, cursive" }}>
					Simply Stated Staging & Designs
				</h3>
				<p className="truncate overflow-hidden ml-4 md:w-7/12 text-regular">
					<b>By Joelle Hau</b>
				</p>
			</div>
			<hr className="md:w-[3px] w-0 h-full bg-black" />
			<ul className="flex flex-row justify-between items-center w-full md:w-3/5 md:pr-56 md:pl-10">
				<div className={link_wrapper_style}>
					<li className={selected("/")} onClick={() => navigate("/")}>
						Home
					</li>
				</div>
				<div className={link_wrapper_style} onMouseEnter={() => set_show_services_menu(true)}>
					<li className={selected("/services")}>Services</li>
					{show_services_menu && <ServicesMenu />}
				</div>
				<div className={link_wrapper_style}>
					<li className={selected("/portfolio")} onClick={() => navigate("/portfolio")}>
						Portfolio
					</li>
				</div>
				<div className={link_wrapper_style}>
					<li className={selected("/about")} onClick={() => navigate("/about")}>
						About
					</li>
				</div>
				<div className={link_wrapper_style}>
					<li
						className={selected("/contact")}
						onClick={() =>
							window.open(
								"https://www.honeybook.com/widget/simply_stated_staging_designs_251564/cf_id/6452d7327d14a1032ef0a989"
							)
						}
					>
						Contact
					</li>
				</div>
			</ul>
		</div>
	);
};

export default NavigationBar;
