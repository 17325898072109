import { useState } from "react";

import Bar from "../../../components/horizontal_bar";
import HeaderOne from "../../../components/headers/header_one";
import HeaderThree from "../../../components/headers/header_three";

import color_palette from "../../../assets/colors";

import { transformations } from "./utils";

const TransformationsSection = () => {
	const [index, set_index] = useState<number>(0);

	const Arrow = ({ onClick }: { onClick: Function }) => {
		return (
			<div
				className={"h-full w-full bg-dark-blue hover:bg-light-blue active:bg-medium-blue hover:cursor-pointer"}
				style={{ clipPath: "polygon(0% 0%, 100% 50%, 0% 100%, 0% 0%)" }}
				onClick={() => onClick()}
			></div>
		);
	};

	return (
		<div className={"md:px-40 flex flex-col items-center w-full mb-10 px-4"}>
			<div className={"flex flex-row items-center mb-2 w-full"}>
				<div className="md:w-[30%] w-0">
					<Bar color={color_palette["dark-blue"]} />
				</div>
				<HeaderOne className={"md:w-[40%] w-full text-center text-md-header font-bold"}>Transformations</HeaderOne>
				<div className="md:w-[30%] w-0">
					<Bar color={color_palette["dark-blue"]} />
				</div>
			</div>
			<div className={"flex flex-col items-center w-full"}>
				<div className={"relative my-4 flex flex-row items-center justify-between w-full md:h-[350px] h-[450px]"}>
					<div className={"flex flex-col items-center w-[30px] h-[55px] mx-2 mt-4 rotate-180"}>
						<Arrow
							onClick={() => {
								if (index - 1 < 0) {
									set_index(4);
								} else {
									set_index(index - 1);
								}
							}}
						/>
					</div>
					<div className={"relative my-4 flex md:flex-row flex-col items-start justify-evenly w-full h-full py-4"}>
						<div className={"flex flex-col items-center md:w-[350px] w-full h-full"}>
							<HeaderThree>Before</HeaderThree>
							<Bar color={color_palette["dark-blue"]} />
							<img
								src={transformations[index].before}
								className={"w-full h-full object-contain rounded-md mt-5 bg-medium-gray"}
								alt={`Before ${index}`}
							/>
						</div>
						<div className={"flex flex-col items-center md:w-[350px] w-full h-full"}>
							<HeaderThree>After</HeaderThree>
							<Bar color={color_palette["dark-blue"]} />
							<img
								src={transformations[index].after}
								className={"w-full h-full object-contain rounded-md mt-5 bg-medium-gray"}
								alt={`After ${index}`}
							/>
						</div>
					</div>
					<div className={"flex flex-col items-center w-[30px] h-[55px] mx-2 mt-4 rotate-0"}>
						<Arrow
							onClick={() => {
								if (index + 1 > 4) {
									set_index(0);
								} else {
									set_index(index + 1);
								}
							}}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default TransformationsSection;
