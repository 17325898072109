import color_palette from "../../../../assets/colors";

export type Element = { number: number; text: string; color: string };

export const elements: Array<Element> = [
	{
		number: 1,
		text: "Fill out the Design Form to get started on your project.",
		color: color_palette["light-blue"],
	},
	{
		number: 2,
		text: "A Lifestyle/Design Questionnaire will be sent to better understand the goal of the redesign.",
		color: color_palette["medium-blue"],
	},
	{
		number: 3,
		text: "We can then schedule a time to review your project needs.",
		color: color_palette["dark-blue"],
	},
];
