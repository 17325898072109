import color_palette from "../../../../assets/colors";

export type Element = {
	title: string;
	description: string;
	color: string;
	number: number;
};

export const elements: Array<Element> = [
	{
		title: "Questionnaire",
		description:
			"A Design Questionnaire will be sent to gather additional information and gain a better understanding of your specific needs: project requirements, design goals and personal vision.",
		color: color_palette["dark-blue"],
		number: 1,
	},
	{
		title: "Prep Work",
		description:
			"I have you take measurements and photographs of your space and gather additional information pertinent to the job.",
		color: color_palette["medium-blue"],
		number: 2,
	},
	{
		title: "Design",
		description:
			"*Next I will create a design plan that includes a cohesive color palette, sourced furniture and finish selections that fit the functional and aesthetic needs of your space.",
		color: color_palette["light-blue"],
		number: 3,
	},
	{
		title: "Final Prep",
		description:
			"*You are presented with an organized design plan with tools and tips that will serve as a “Do It Yourself” guide to implement the design on your own. (Comprehensive plans include To The Trade Shopping Links)",
		color: color_palette["medium-blue"],
		number: 4,
	},
];
