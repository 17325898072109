import color_palette from "../../../../assets/colors";

export type NumberElement = {
	number: number;
	color: string;
	description: string;
};

export const number_items: Array<NumberElement> = [
	{
		color: color_palette["dark-blue"],
		number: 1,
		description:
			"Number one reason a buyer purchases a particular home is the home felt warm, inviting and the buyer made an emotional connection.",
	},
	{
		color: color_palette["medium-blue"],
		number: 2,
		description: "Without furniture, there is no frame of reference and it’s hard to tell the scale and size of a room.",
	},
	{
		color: color_palette["light-blue"],
		number: 3,
		description: "When a room is empty, buyers tend to focus on the negative details.",
	},
	{
		color: color_palette.white,
		number: 4,
		description: "Only 10% of home buyers can actually visualize the potential of a home.",
	},
];
