import HeaderOne from "../../../../components/headers/header_one";
import HeaderThree from "../../../../components/headers/header_three";
import Text from "../../../../components/paragraph";

import color_palette from "../../../../assets/colors";

import CouchAndPillows from "../../../../assets/images/stock_images/couch_and_pillows_green.jpg";

const VacantStagingSection = () => {
	return (
		<div className={"relative flex md:flex-row flex-col items-center justify-between md:px-40 my-10 px-4"}>
			<img
				className={"md:w-[37%] w-[80%] shadow-regular rounded-md"}
				src={CouchAndPillows}
				alt={"Beige counch with green and beige pillows and blanket"}
			/>
			<div className={"flex flex-col items-start md:ml-10 md:w-[60%] w-full mt-8"}>
				<div className={"bg-dark-blue md:w-full w-full rounded-md pl-2"}>
					<HeaderOne color={color_palette.white}>Vacant Home Staging Consultation</HeaderOne>
				</div>
				<div className={"md:w-full w-full flex flex-col md:items-start items-center justify-evenly md:h-[250px] h-[350px]"}>
					<HeaderThree>I can style selected rooms or the entire home.</HeaderThree>
					<Text>
						Homes that are empty are in need of staging. The need to stage a vacant home is more critical than for a
						furnished home. My goal will be to add enough home furnishings in order to give the space a warm, open and
						comfortable feel that provide buyers a frame of reference and fuels their imagination. Starting with an
						assessment, I will deliver a proposal and work to meet your budget.
					</Text>
				</div>
			</div>
		</div>
	);
};

export default VacantStagingSection;
