import React, { useState } from "react";

import color_palette from "../../assets/colors";

type ButtonColors = {
	border?: string;
	backgroundColor?: string;
	color?: string;
};

type Props = {
	className?: string;
	children: React.ReactNode;
	default_color?: string;
	hover_color?: string;
	active_color?: string;
	onClick: Function;
};

const Button = ({ className, children, onClick, default_color, hover_color, active_color }: Props) => {
	const default_style: ButtonColors = {
		border: `2px solid ${default_color ?? color_palette["medium-blue"]}`,
		backgroundColor: "transparent",
		color: default_color ?? color_palette["medium-blue"],
	};

	const hover_style: ButtonColors = {
		border: `2px solid ${hover_color ?? color_palette["light-blue"]}`,
		backgroundColor: hover_color ?? color_palette["light-blue"],
		color: color_palette.white,
	};

	const active_style: ButtonColors = {
		border: `2px solid ${active_color ?? color_palette["dark-blue"]}`,
		backgroundColor: active_color ?? color_palette["dark-blue"],
		color: color_palette.white,
	};

	const [color, set_color] = useState<ButtonColors>(default_style);

	const button_style: string =
		className ??
		`
			border-2 
			text-white 
			h-[33px] 
			w-[235px] 
			rounded-md 
		`;

	return (
		<button
			className={button_style}
			style={color}
			onClick={() => onClick()}
			onMouseEnter={() => set_color(hover_style)}
			onMouseLeave={() => set_color(default_style)}
			onMouseDown={() => set_color(active_style)}
			onMouseUp={() => set_color(default_style)}
		>
			{children}
		</button>
	);
};

export default Button;
