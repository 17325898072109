import { useNavigate } from "react-router-dom";

import Button from "../../../components/button";
import HeaderOne from "../../../components/headers/header_one";

import color_palette from "../../../assets/colors";

import DiningRoomAndKitchen from "../../../assets/images/home_images/dining_room_and_kitchen.jpg";

const IntroSection = () => {
	const navigate = useNavigate();

	return (
		<div className={"w-full relative md:h-[300px] h-[250px]"}>
			<div className={"relative absolute z-0 top-0 left-0 overflow-hidden w-full h-full"}>
				<img
					className={"absolute md:top-[-315px]"}
					src={DiningRoomAndKitchen}
					alt="Design template with matching furniture"
				/>
			</div>
			<div className={"absolute z-10 top-0 left-0 bg-black opacity-[65%] w-full h-full"}></div>
			<div className={"absolute z-20 top-0 left-0 w-full h-full md:px-60 md:py-12 py-6 flex flex-col items-center px-4"}>
				<div className={"mb-10"}>
					<HeaderOne color={color_palette.white}>Explore My Designs!</HeaderOne>
				</div>
				<p className={"text-white mb-10"}>Let's Connect About Your Project</p>
				<Button
					default_color={color_palette.white}
					onClick={() =>
						window.open(
							"https://www.honeybook.com/widget/simply_stated_staging_designs_251564/cf_id/6452d7327d14a1032ef0a989"
						)
					}
				>
					Contact Me
				</Button>
			</div>
		</div>
	);
};

export default IntroSection;
