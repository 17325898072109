import { useNavigate } from "react-router-dom";

import Bar from "../../../components/horizontal_bar";
import Button from "../../../components/button";
import HeaderOne from "../../../components/headers/header_one";
import HeaderThree from "../../../components/headers/header_three";
import Text from "../../../components/paragraph";

import { elements, Element } from "./utils";

import color_palette from "../../../assets/colors";

const DesignApproachSection = () => {
	const navigate = useNavigate();

	const AcronymLetter = ({ letter, title, color }: Element) => {
		return (
			<div className="flex flex-row items-center w-full">
				<div
					className={`flex items-center justify-center text-white w-16 h-16 rounded-full`}
					style={{ border: `3px solid ${color}` }}
				>
					<span className="text-[47px] p-0 m-0 pb-1 text-black">{letter}</span>
				</div>
				<div className="flex flex-col items-start w-full">
					<HeaderThree className={"text-sm-header font-medium pl-4"}>{title}</HeaderThree>
					<Bar color={color} />
				</div>
			</div>
		);
	};

	return (
		<div className="relative w-full border-[3px] border-dark-blue my-10 md:px-12 px-4 rounded-md md:w-4/5">
			<div className="absolute top-[-50px] left-0 w-full h-20 md:px-40 px-4">
				<HeaderOne
					className="h-16 text-center text-lg-header font-medium bg-dark-blue pt-2 pb-2 rounded-md"
					color={color_palette["white"]}
				>
					Design Approach
				</HeaderOne>
			</div>
			<div className={"md:px-24 text-center mt-10 px-4"}>
				<Text>
					My design approach is to use the 8 Steps in each room so that you FEEL HOME in your own home. I will use what you
					already have and love to create environments and spaces that seem fresh, new, updated and comfortable for you and
					your family. (I can also create a budget and shop for those accessories that add the WOW factor to each space in
					your home.)
				</Text>
			</div>
			{elements.map(({ letter, title, color }: Element, index: number) => {
				return (
					<div key={index} className={"my-[10px]"}>
						<AcronymLetter letter={letter} title={title} color={color} />
					</div>
				);
			})}
			<div className={"w-full flex flex-col items-center mb-10 mt-4"}>
				<Button onClick={() => navigate("/")}>My Design Philosophy</Button>
			</div>
		</div>
	);
};

export default DesignApproachSection;
