import color_palette from "../../../assets/colors";

export type Element = {
	letter: string;
	title: string;
	color: string;
};

export const elements: Array<Element> = [
	{
		color: color_palette["dark-blue"],
		letter: "F",
		title: "First Impressions",
	},
	{
		color: color_palette["medium-blue"],
		letter: "E",
		title: "Eliminating Clutter",
	},
	{
		color: color_palette["light-blue"],
		letter: "E",
		title: "Emphasizing Positive Elements",
	},
	{
		color: color_palette["medium-blue"],
		letter: "L",
		title: "Lighten Up",
	},
	{
		color: color_palette["medium-blue"],
		letter: "H",
		title: "Home Buyer's Appeal",
	},
	{
		color: color_palette["light-blue"],
		letter: "O",
		title: "Obligations",
	},
	{
		color: color_palette["medium-blue"],
		letter: "M",
		title: "Modernizing",
	},
	{
		color: color_palette["dark-blue"],
		letter: "E",
		title: "Emotional Connection Points",
	},
];
