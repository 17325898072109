import { FC } from "react";

type Props = {
	left?: boolean;
	onClick: Function;
};

const Arrow: FC<Props> = ({ onClick, left = false }) => {
	return (
		<div
			className={"h-full w-full bg-dark-blue hover:bg-light-blue active:bg-medium-blue hover:cursor-pointer"}
			style={{ clipPath: "polygon(0% 0%, 100% 50%, 0% 100%, 0% 0%)", transform: left ? "rotate(180deg)" : "" }}
			onClick={() => onClick()}
		></div>
	);
};

export default Arrow;
