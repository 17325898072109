export type Element = {
	title: string;
	description: string;
};

export const elements: Array<Element> = [
	{
		title: "Visualize Your Space",
		description: "Provides a detailed and compelling “visual” where the products can be seen within the space.",
	},
	{
		title: "Avoiding Design Mistakes",
		description:
			"Helps you avoid costly mistakes because a plan with a visual sets the expectation of the room while showing items that may not fit the space or the overall budget.",
	},
	{
		title: "Saving Time",
		description:
			"Saves time on sourcing for products in an overwhelming online space. I can provide my expertise in shopping and knowing where to find those perfect items.",
	},
	{
		title: "Providing Cohesion",
		description:
			"Creates a “cohesive” plan for your space before spending on expensive items that don't work well with one another.",
	},
	{
		title: "Affordability",
		description: "Budget friendly since you are paying for the expertise and not the time consuming shopping of the designer.",
	},
];
