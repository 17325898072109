import HeaderOne from "../../../../components/headers/header_one";

import DesignExpertLogo from "../../../../assets/icons/design_expert_logo.png";
import MountainScene from "../../../../assets/images/stock_images/outdoor_mountain_scene.jpg";

import color_palette from "../../../../assets/colors";

const IntroSection = () => {
	return (
		<div className={"w-full relative md:h-[350px] h-[250px]"}>
			<div className={"relative absolute z-0 top-0 left-0 overflow-hidden w-full h-full"}>
				<img className={"absolute md:top-[-370px]"} src={MountainScene} alt="Design template with matching furniture" />
			</div>
			<div className={"absolute z-10 top-0 left-0 bg-black opacity-[65%] w-full h-full"}></div>
			<div
				className={
					"absolute z-20 top-0 left-0 w-full h-full md:px-60 md:py-20 py-6 flex flex-col items-center px-4 text-center"
				}
			>
				<img
					className={"md:h-[150px] md:w-[150px] w-[110px] h-[110px] mb-[10px]"}
					src={DesignExpertLogo}
					alt="Design expert symbol"
				/>
				<HeaderOne color={color_palette.white}>Airbnb and Vacation Rentals</HeaderOne>
			</div>
		</div>
	);
};

export default IntroSection;
