import React, { useEffect } from "react";

import ClosingSection from "./closing_section";
import DesignApproachSection from "./design_approach_section";
import EducationSection from "./education_section";
import IntroSection from "./intro_section";
import OwnerSection from "./owner_section";

import { LoadingProps } from "../../common/types";

const About: React.FC<LoadingProps> = ({ set_loading }) => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
		});

		set_loading(true);
	}, []);

	return (
		<div className={"flex flex-col items-center pt-20 w-full mb-20"}>
			<IntroSection />
			<OwnerSection />
			<EducationSection />
			<DesignApproachSection />
			<ClosingSection />
		</div>
	);
};

export default About;
