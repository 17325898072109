import HeaderOne from "../../../components/headers/header_one";

import color_palette from "../../../assets/colors";

import MomPicture from "../../../assets/images/mom_pictures/mom_picture_porch.jpg";

const OwnerSection = () => {
	return (
		<div className="relative w-full h-[190px] max-[1415px]:h-[200px] max-[1225px]:h-[215px] max-[1125px]:h-[250px] max-[1000px]:h-[270px] border-[3px] border-dark-blue my-10 rounded-md mb-64 md:mb-0 md:w-4/5">
			<div className="relative absolute top-[-50px] w-full h-20 md:px-40 px-4">
				<HeaderOne
					className="h-16 text-center text-lg-header font-medium bg-dark-blue pt-2 pb-2 rounded-md"
					color={color_palette["white"]}
				>
					Meet the Owner
				</HeaderOne>
				<div className="absolute top-[55px] right-[90px] md:top-[30px] w-56 h-60 overflow-hidden shadow-regular rounded-md z-10">
					<img alt={"Joelle Hau"} style={{ objectPosition: "55% 27%" }} src={MomPicture} />
				</div>
				<p className="absolute md:w-full top-[280px] md:top-[70px] md:left-0 text-left md:pl-40 md:pr-[340px] text-regular md:border-0 border-[3px] border-dark-blue w-88 right-[-40px] md:bg-transparent bg-white rounded-md p-4">
					I took my love for decorating and my eye for design by refining and building my skill set while earning a
					certificate for Staging and Design through the most recognized and accredited home staging course in the industry
					with the program offered through The Home Staging Resource. In addition, I continue to educate myself through HSR
					by attending webinars offered on the latest and newest trends of the industry.
				</p>
			</div>
		</div>
	);
};

export default OwnerSection;
