import HeaderOne from "../../../../components/headers/header_one";
import HeaderThree from "../../../../components/headers/header_three";
import Text from "../../../../components/paragraph";

import { Element, elements } from "./utils";

import DesignTemplate from "../../../../assets/images/design_templates/design_template.jpg";
import EDesignLogo from "../../../../assets/icons/white_e_design_logo.png";

import color_palette from "../../../../assets/colors";

const IntroSection = () => {
	const WhyElement = ({ title, description }: Element) => {
		return (
			<div className={"w-full flex flex-col items-start"}>
				<HeaderThree color={color_palette.white}>{title}</HeaderThree>
				<Text color={color_palette.white}>{description}</Text>
			</div>
		);
	};

	return (
		<div className={"relative w-full md:h-[900px] h-[1250px]"}>
			<div className="absolute z-0 top-0 left-0 min-w-[68vw] h-full w-full overflow-hidden">
				<img src={DesignTemplate} alt="Design template with matching furniture" />
			</div>
			<div className={"absolute z-10 top-0 left-0 min-w-[68vw] h-full bg-black opacity-[75%] w-full"}></div>
			<div className={"absolute z-20 left-0 top-0 w-full h-full md:px-48 py-10 flex flex-col items-center px-4"}>
				<img className={"h-[180px] w-[180px] mb-[10px]"} src={EDesignLogo} alt="White e-design logo" />
				<div className={"my-[10px] text-left w-full"}>
					<HeaderOne color={color_palette.white}>E-Design: A New Way to Design</HeaderOne>
				</div>
				<div className={"my-[10px] text-left w-full"}>
					<HeaderThree color={color_palette.white}>What is E-Designing?</HeaderThree>
				</div>
				<Text color={color_palette.white}>
					E-Design is using technology to allow a designer to provide inspiration, expert sourcing and design expertise in
					a detailed room plan with product links at a fraction of the cost of full service design.
				</Text>
				<div className={"my-[10px] text-left w-full"}>
					<HeaderThree color={color_palette.white}>Why E-Design</HeaderThree>
				</div>
				<div className={"flex flex-col items-start"}>
					{elements.map(({ title, description }: Element, index: number) => {
						return (
							<div key={index} className={"my-[10px]"}>
								<WhyElement title={title} description={description} />
							</div>
						);
					})}
				</div>
			</div>
		</div>
	);
};

export default IntroSection;
