import Bar from "../../../components/horizontal_bar";

import color_palette from "../../../assets/colors";

import PictureFrameStockImage from "../../../assets/images/stock_images/coffee_mug_and_blank_picture.jpg";
import BookStockImage from "../../../assets/images/stock_images/coffee_mug_and_white_book.jpg";

const WhySection = () => {
	return (
		<div className="flex flex-col items-center w-full bg-dark-gray my-10 py-10 text-regular text-white text-left">
			<h1 className="text-center text-white text-lg-header font-medium underline underline-offset-[16px] decoration-[3px] decoration-light-blue">
				Why Choose Simply Stated?
			</h1>
			<div className="flex md:flex-row flex-col items-center md:space-x-8 md:w-4/5 w-full mt-10 px-8">
				<img
					src={PictureFrameStockImage}
					alt={"Stock White Frame"}
					className="md:w-[550px] md:h-[350px] rounded-md w-full"
				/>
				<div className="flex flex-col items-start space-y-8 md:mt-0 mt-4 w-full">
					<div className="flex flex-col items-start space-y-1 relative">
						<h3 className="text-md-header font-medium text-white">Get Top Dollar</h3>
						<Bar color={color_palette["light-blue"]} />
						<p>
							Every staging statistic supports the fact that having your home professionally staged and photographed is
							the first step to a successful sale. You will see huge returns on investment.{" "}
						</p>
					</div>
					<div className="flex flex-col items-start space-y-4">
						<h3 className="text-md-header font-medium text-white">Client Collaboration</h3>
						<p>As a stager and redesigner, I will work with my clients on styling tips, room redos or home makeovers.</p>
					</div>
				</div>
			</div>
			<div className="flex md:flex-row flex-col items-center md:space-x-8 md:w-4/5 w-full mt-10 px-8">
				<div className="flex flex-col items-start space-y-8 md:mb-0 mb-4">
					<div className="flex flex-col items-start space-y-1">
						<h3 className="text-md-header font-medium text-white">Easy Checklist</h3>
						<Bar color={color_palette["light-blue"]} />
						<p>
							Using 8 design steps in each room of your home, I work with you to fill out a detailed staging report
							during our consultation. I make your home shine using what you have!{" "}
						</p>
					</div>
					<div className="flex flex-col items-start space-y-4">
						<h3 className="text-md-header font-medium text-white">Stress-Free</h3>
						<p>
							I take the stress and hard work out of preparing your home to sell and can even do it for you! I also
							give you strategies for easy organization you can live with!
						</p>
					</div>
				</div>
				<img src={BookStockImage} alt={"Stock White Book"} className="md:w-[550px] md:h-[350px] rounded-md w-full" />
			</div>
		</div>
	);
};

export default WhySection;
