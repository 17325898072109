import { useEffect, useState } from "react";

import Footer from "./partials/footer";
import Loader from "./partials/loader";
import NavigationBar from "./partials/navigation_bar";
import Router from "./routes";

import "./App.css";

function App() {
	const [loading, set_loading] = useState<boolean>(true);

	const load_images = () => setTimeout(() => set_loading(false), 1500);

	useEffect(() => {
		if (loading) load_images();
	}, [loading]);

	return (
		<div className="bg-white relative flex flex-col items-center w-full overflow-hidden md:min-w-[1250px]">
			{loading && (
				<div className="fixed w-full h-full flex flex-col items-center justify-center top-0 left-0 z-[1000] bg-white">
					<Loader />
				</div>
			)}
			<NavigationBar />
			<Router set_loading={set_loading} />
			<Footer />
		</div>
	);
}

export default App;
