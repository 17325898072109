import React, { useEffect } from "react";

import BenefitsSection from "./benefits_section";
import IntroSection from "./intro_section";
import PhasesSection from "./phases_section";
import PorchSection from "./porch_section";
import SpecificRoomSection from "./specific_rooms_section";
import VignetteSection from "./vignette_section";

import { LoadingProps } from "../../../common/types";

const RedesignServices: React.FC<LoadingProps> = ({ set_loading }) => {
	useEffect(() => {
		window.scrollTo({
			top: 0,
		});

		set_loading(true);
	}, []);

	return (
		<div className={"flex flex-col items-center pt-20 w-full mb-20"}>
			<IntroSection />
			<SpecificRoomSection />
			<VignetteSection />
			<PorchSection />
			<PhasesSection />
			<BenefitsSection />
		</div>
	);
};

export default RedesignServices;
