import Bar from "../../../components/horizontal_bar";
import HeaderOne from "../../../components/headers/header_one";
import Text from "../../../components/paragraph";

import color_palette from "../../../assets/colors";

const EducationSection = () => {
	return (
		<div className={"flex flex-col items-center my-10 bg-dark-gray py-10 text-center md:px-40 px-4"}>
			<div className={"flex flex-col items-center md:w-5/12 w-full mb-10"}>
				<HeaderOne color={color_palette["dark-blue"]}>Education and Work</HeaderOne>
				<Bar color={color_palette["dark-blue"]} />
			</div>
			<Text color={color_palette.white}>
				I graduated with a Liberal Arts Degree in Education where I learned a variety of transferable skills rather than just
				one specific skill set. I implemented those learned skills in the classroom where I taught 6th grade reading. During
				my time as an educator, I discovered my personal interests and my personal strengths, which sparked my creativity
				through lessons, activities and projects I created and presented in the classroom. Fast forward to today, I decided
				to take my love for creating, designing and decorating in forging a new career path into the industry of Staging and
				Design forming Simply Stated Staging & Designs, LLC where I can showcase my talent and my “eye for design”.
			</Text>
		</div>
	);
};

export default EducationSection;
