import React from "react";

type Props = {
	className?: string;
	children: React.ReactNode;
	color?: string;
	onClick?: Function;
};

const Text = ({ className, children, color, onClick }: Props) => {
	return (
		<p
			className={className ?? "text-regular font-normal"}
			style={{ color: color ?? "black" }}
			onClick={onClick ? () => onClick() : undefined}
		>
			{children}
		</p>
	);
};

export default Text;
