import HeaderOne from "../../../components/headers/header_one";

import ASHSRCert from "../../../assets/images/certification_images/ashsr_cert.jpg";
import EDesignCert from "../../../assets/images/certification_images/e_design_cert.png";
import RESA from "../../../assets/images/certification_images/resa_cert.jpg";
import StagingCert from "../../../assets/images/certification_images/home_staging_cert.png";
import VacationCert from "../../../assets/images/certification_images/vacation_rental_cert.png";

import color_palette from "../../../assets/colors";

const HomeCertSection = () => {
	return (
		<div className="relative w-full md:h-[300px] h-[400px] border-[3px] border-dark-blue md:mt-10 md:mb-20 my-10 rounded-md md:px-28 px-4 md:w-4/5">
			<div className="relative absolute top-[-50px] h-18">
				<HeaderOne
					color={color_palette.white}
					className="h-full text-center text-lg-header font-medium bg-dark-blue pt-2 pb-2 rounded-md"
				>
					Certifications & Memberships
				</HeaderOne>
			</div>
			<div className="absolute w-full top-[25px] left-[0px] md:px-24 px-6 grid grid-cols-6 md:pt-0 pt-12">
				<img
					className="md:w-[275px] md:h-[185px] w-[160px] h-[110px] md:col-span-2 col-span-3"
					src={StagingCert}
					alt={"Staging and Design Certification"}
				/>
				<img
					className="md:w-[275px] md:h-[185px] w-[160px] h-[110px] md:col-span-2 col-span-3"
					src={VacationCert}
					alt={"Vacation Staging Certification"}
				/>
				<img
					className="md:w-[275px] md:h-[185px] w-[160px] h-[110px] md:col-span-2 col-span-3"
					src={EDesignCert}
					alt={"E-Design Certification"}
				/>
				<img
					className="md:w-[275px] md:h-[185px] w-[160px] h-[110px] md:col-start-2 md:col-span-2 col-span-3"
					src={RESA}
					alt={"RESA Membership"}
				/>
				<img
					className="md:w-[275px] md:h-[185px] w-[160px] h-[110px] md:col-span-3 col-start-1 col-span-3"
					src={ASHSRCert}
					alt={"ASHSR Membership"}
				/>
			</div>
		</div>
	);
};

export default HomeCertSection;
