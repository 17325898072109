import { FC, useEffect, useState } from "react";
import * as pdfjs from "pdfjs-dist";
import "pdfjs-dist/build/pdf.worker.min";

import Arrow from "../arrow";

type Props = {
	url: string;
	id: string;
};

const PDFBed: FC<Props> = ({ url, id }) => {
	const [page, set_page] = useState<number>(1);
	const [pdf_document, set_pdf_document] = useState<pdfjs.PDFDocumentProxy | undefined>(undefined);

	useEffect(() => {
		pdfjs.GlobalWorkerOptions.workerSrc = "//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js";

		pdfjs.getDocument(url).promise.then(async (pdf) => {
			set_page(1);
			set_pdf_document(pdf);
			display_page(1, pdf);
		});
	}, []);

	const display_page = async (page_number: number, pdf_document: pdfjs.PDFDocumentProxy) => {
		if (page && pdf_document && page <= pdf_document.numPages) {
			const new_page = await pdf_document.getPage(page_number);
			const canvas = document.getElementById(id) as HTMLCanvasElement;
			const context = canvas.getContext("2d");
			const viewport = new_page.getViewport({ scale: 1.5 });

			canvas.width = viewport.width;
			canvas.height = viewport.height;

			if (context) {
				await new_page.render({
					canvasContext: context,
					viewport: viewport,
				});

				set_page(page_number);
			}
		}
	};

	const next_page = async (page_num: number) => {
		if (pdf_document) {
			if (page_num === pdf_document.numPages + 1) {
				display_page(1, pdf_document);
			} else if (page_num === 0) {
				display_page(pdf_document.numPages, pdf_document);
			} else {
				display_page(page_num, pdf_document);
			}
		}
	};

	return (
		<div className="w-full md:h-[500px] md:px-[400px] px-4 flex flex-row items-center justify-between h-[415px] mb-4">
			<div className="w-[35px] h-[75px] mr-[15px]">
				<Arrow left onClick={() => next_page(page - 1)} />
			</div>
			<div className="h-full w-full flex flex-col items-center">
				<canvas className={"h-full w-full p-4 bg-dark-gray rounded-lg"} id={id}></canvas>
				<p className="mt-[5px] font-bold text-sm-header">
					{page}/{pdf_document?.numPages}
				</p>
			</div>
			<div className="w-[35px] h-[75px] ml-[15px]">
				<Arrow onClick={() => next_page(page + 1)} />
			</div>
		</div>
	);
};

export default PDFBed;
