import React, { useEffect } from "react";

import PDFBed from "../../../components/pdf_bed";
import IntroSection from "./intro_section";
import SalesSection from "./sales_section";
import ServicePhasesSection from "./service_phases_section";
import VacantStagingSection from "./vacant_staging_section";
import VacantStagingWhySection from "./vacant_staging_why_section";

import { LoadingProps } from "../../../common/types";

const StagingServices: React.FC<LoadingProps> = ({ set_loading }) => {
	const top_dollar_guide_url: string = "/pdfs/top_dollar_guide.pdf";
	const vacant_staging_guide_url: string = "/pdfs/vacant_staging_guide.pdf";

	useEffect(() => {
		window.scrollTo({
			top: 0,
		});

		set_loading(true);
	}, []);

	return (
		<div className={"flex flex-col items-center py-20 w-full mb-20"}>
			<IntroSection />
			<SalesSection />
			<ServicePhasesSection />
			<VacantStagingSection />
			<PDFBed url={vacant_staging_guide_url} id={"vacant_staging_canvas"} />
			<VacantStagingWhySection />
			<PDFBed url={top_dollar_guide_url} id={"top_dollar_guide_canvas"} />
		</div>
	);
};

export default StagingServices;
