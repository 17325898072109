import Bar from "../../../components/horizontal_bar";
import HeaderOne from "../../../components/headers/header_one";

import { PhotoSet, photo_sets } from "./utils";

const RoomsSection = () => {
	const DisplaySection = ({ images, title, color }: PhotoSet) => {
		return (
			<div className={"flex flex-col items-center my-4"}>
				<div className={"flex flex-row items-center mb-4 w-full"}>
					<div className={"md:w-1/4 w-0"}>
						<Bar color={color} />
					</div>
					<HeaderOne className={"md:w-6/12 w-full text-center text-md-header font-bold"}>{title}</HeaderOne>
					<div className={"md:w-1/4 w-0"}>
						<Bar color={color} />
					</div>
				</div>
				<div className={"flex md:flex-row flex-col items-center justify-between"}>
					{images.map((image_url) => {
						return (
							<img
								className={"w-[300px] h-[225px] object-contain rounded-md bg-dark-gray md:mx-2 mx-0 my-2 md:my-0"}
								src={image_url}
								alt={"Home Decor Pic"}
							/>
						);
					})}
				</div>
			</div>
		);
	};

	return (
		<div className={"my-10 md:px-40 w-full flex flex-col items-center px-4"}>
			{photo_sets.map(({ title, color, images }: PhotoSet) => {
				return <DisplaySection title={title} color={color} images={images} />;
			})}
		</div>
	);
};

export default RoomsSection;
