import color_palette from "../../../../assets/colors";

export const phase_box_style: Array<{ header: string; body: string; color: string }> = [
	{
		header: "Phase One",
		body: "(Consultation and Detailed Staging Report) - Provide expert advice on how to best stage a home using what you already own maximizing staging potential.",
		color: color_palette["dark-gray"],
	},
	{
		header: "Phase Two",
		body: "Shopping for items that will go with what you own to modernize the space.",
		color: color_palette["medium-gray"],
	},
	{
		header: "Phase Three",
		body: "(Restyling) - Restyle or Stage the home using the existing decor, existing furniture or incorporating new purchases to optimize return on investment.",
		color: color_palette["light-gray"],
	},
];
