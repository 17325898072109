export const rooms: Array<string> = [
	"Family Room/Living Room",
	"Formal Dining Room",
	"Kitchen",
	"Master Bedroom",
	"Master Bathroom",
	"Entry",
	"Home Office",
	"Nursery",
	"Breakfast Nook",
];
