import { useNavigate } from "react-router-dom";

import Text from "../../components/paragraph";

const Footer = () => {
	const navigate = useNavigate();

	return (
		<div className="flex flex-col items-center w-full h-[125px] text-regular font-normal bg-dark-gray absolute bottom-0">
			<div className="flex md:flex-row flex-col items-center justify-evenly w-full h-full">
				<Text className={"text-regular font-normal cursor-pointer"} color="white" onClick={() => navigate("/about")}>
					Joelle Hau
				</Text>
				<Text color="white">(303) 859-7425</Text>
				<Text
					className={"text-regular font-normal cursor-pointer"}
					color="white"
					onClick={() =>
						window.open(`https://mail.google.com/mail/?view=cm&fs=1&to=joellehau@simply-stated-staging-designs.com`)
					}
				>
					joellehau@simply-stated-staging-designs.com
				</Text>
			</div>
		</div>
	);
};

export default Footer;
