// Living Room Images
import MainLivingRoom from "../../../assets/images/home_images/main_living_room_image.jpg";
import NiagaraMainLivingRoom from "../../../assets/images/home_images/niagara_main_living_room.webp";
import OldHouseLivingRoom from "../../../assets/images/home_images/old_house_living_room.webp";

// Kitchen Images
import BasementKitchenPhoto from "../../../assets/images/home_images/basement_kitchen_photo.jpg";
import MainKitchenPhoto from "../../../assets/images/home_images/main_kitchen_photo.jpg";
import NiagaraKitchenPhoto from "../../../assets/images/home_images/niagara_main_kitchen.webp";

// Bedroom Images
import NiagaraMasterBedroom from "../../../assets/images/home_images/niagara_master_bedroom.webp";
import NiagaraSmallBedroom from "../../../assets/images/home_images/niagara_small_bedroom.webp";
import SmallBedroom from "../../../assets/images/home_images/small_bedroom.jpg";

// Dining Room Images
import FrontDiningRoom from "../../../assets/images/home_images/dining_room_front.jpg";
import FullDiningRoom from "../../../assets/images/home_images/full_dining_room.jpg";
import NiagaraDiningRoom from "../../../assets/images/home_images/niagara_dining_room.webp";

// Front Porches
import FallFrontPorch from "../../../assets/images/home_images/fall_front_porch.jpg";
import FrontPorch from "../../../assets/images/home_images/front_porch.jpg";
import NiagaraFrontPorch from "../../../assets/images/home_images/niagara_front_porch.webp";

// Back Patios/Porches
import BackPatio from "../../../assets/images/home_images/back_porch.jpg";
import NiagaraBackPatio from "../../../assets/images/home_images/niagara_back_porch.webp";
import NiagaraOtherBackPatio from "../../../assets/images/home_images/niagara_other_back_porch.webp";

// Vignettes
import CoffeeVignette from "../../../assets/images/home_images/coffee_vignette.jpg";
import OtherWineVignette from "../../../assets/images/home_images/wine_other_vignette.jpg";
import WinterVignette from "../../../assets/images/home_images/winter_vignette.jpg";

import color_palette from "../../../assets/colors";

export type PhotoSet = {
	title: string;
	color: string;
	images: Array<string>;
};

export const photo_sets: Array<PhotoSet> = [
	{
		title: "Living Rooms/Family Rooms",
		color: color_palette["dark-blue"],
		images: [MainLivingRoom, NiagaraMainLivingRoom, OldHouseLivingRoom],
	},
	{
		title: "Kitchens",
		color: color_palette["medium-blue"],
		images: [BasementKitchenPhoto, NiagaraKitchenPhoto, MainKitchenPhoto],
	},
	{
		title: "Bedrooms",
		color: color_palette["light-blue"],
		images: [NiagaraMasterBedroom, SmallBedroom, NiagaraSmallBedroom],
	},
	{
		title: "Dining Rooms",
		color: color_palette["light-blue"],
		images: [FrontDiningRoom, NiagaraDiningRoom, FullDiningRoom],
	},
	{
		title: "Porches",
		color: color_palette["medium-blue"],
		images: [NiagaraFrontPorch, FrontPorch, FallFrontPorch],
	},
	{
		title: "Patios/Backyards",
		color: color_palette["medium-blue"],
		images: [BackPatio, NiagaraBackPatio, NiagaraOtherBackPatio],
	},
	{
		title: "Vignettes",
		color: color_palette["dark-blue"],
		images: [CoffeeVignette, OtherWineVignette, WinterVignette],
	},
];
